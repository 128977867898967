import React, { useContext } from 'react'

import useResponsive from 'contexts/mediaQuery/useResponsive'
import progressContext from 'contexts/progress/context'
import Data from 'contexts/data'

import Box from '../../components/Box'
import Button from '../../components/Button'
import Link from '../../components/Link'
import Text from '../../components/Text'
import Board from '../../components/Board'
import TableModule from '../../components/TableModule'
import TableMobileModule from '../../components/TableMobileModule'
import Label from '../../components/Label';
import { responsive } from '../../components/ThemeProvider/theme';

const theaderData = [
  {
    title: '指定調解日期',
    key: 'advicedate',
    textAlign: responsive('auto', null, 'center'),
    render: (children, data) =>
      <Box.Inline textAlign="left">
        <Text>{children.replace(/[-]/g, '/')}</Text>
        <Text>{data.advicetime.slice(0, 2) + ':' + data.advicetime.slice(2, 4)}</Text>
      </Box.Inline>
  },
  {
    title: '受理公所',
    key: 'department',
    textAlign: responsive('auto', null, 'center'),
  },
  {
    title: '調解場所',
    key: 'medvenue',
    textAlign: responsive('auto', null, 'center'),
  },
  {
    title: '調解會地址',
    key: 'address',
    textAlign: responsive('auto', null, 'center'),
    render: (children, data) =>`${data.zip}${children}`
  },
  {
    title: '調解會電話',
    key: 'phone',
    textAlign: responsive('auto', null, 'center'),
  },
  {
    title: '處理進度',
    key: 'sts',
    textAlign: responsive('auto', null, 'center'),
  },
]

const mobileData = theaderData.map(data => (
  {
    mt: '1.25rem',
    ...data,
    render: (children, d) => (
      <>
        <Label>{data.title}</Label>
        <Text>
          {data.key === 'address' && d.zip}
          {data.key === 'advicedate' ? children.replace(/[-]/g, '/') : children}</Text>
        {data.key === 'advicedate' && <Text>{d.advicetime.slice(0, 2) + ':' + d.advicetime.slice(2, 4)}</Text>}
      </>
    ),
  })
)

const ResultPage = () => {
  const { res } = useContext(progressContext)
  const { isTablet } = useResponsive()
  if (!res || !res.length) return (
    <Board textAlign="center" py="2rem">
      <Text fontSize="1.5em" fontWeight="bold">查無資料</Text>
    </Board>
  )
  return (
    <Data path="org">
      {(d) => {
        if (!d) return null
        const comboData = [Object.assign(res[0], d[res[0].acceptorgid - 1])]
        return (
          isTablet ? (
            <Box>
              <Text fontSize="1.375em" fontWeight="bold" mb="1em">線上調解聲請進度查詢</Text>
              <TableMobileModule keyData={mobileData} data={comboData} />
            </Box>
          ) : (
            <TableModule theaderData={theaderData} data={comboData} />
          )
        )}}
    </Data>
  )
}

export default () => (
  <>
    <ResultPage />
    <Box textAlign="center" my="2em">
      <Button as={Link} to="/progress">返回查詢</Button>
    </Box>
  </>
)
